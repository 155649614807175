<template>
  <div class="container-paises" :class="{ girar: paises }">
    <!-- Selected -->
    <div @click="paises = !paises" :class="'item-pais pais-selecionado'">
      <label class="text-color-item">{{ $t('locales.label') }}</label>
      <span class="title-language">{{ pais_nome }}</span>
      <IconSax class="arrow-up"  name="arrow-up-2" size="1rem" />
    </div>
    <ul class="paises" data-anima="top" v-show="paises">
      <!-- Options -->
      <div v-for="list in filteredItems" :key="list.sigla">
        <li :class="'item-pais-list'" @click="changeCountry(list.sigla, list.pais)">
          <span>{{ list.pais }}</span>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import MetaService from "@/services/resources/MetaService";
const service = MetaService.build();
import Cookies from "js-cookie";

import MemberMetaService from "@/services/resources/MemberMetaService";
const serviceMemberMeta = MemberMetaService.build();

export default {
  name: "Locales",
  props: ["off"],
  data() {
    return {
      pais: "",
      paises: false,
      pais_nome: "",
      pais_code: "",
      search: "",
      showSelectedImg: false,
      lists: [],
    };
  },
  computed: {
    filteredItems() {
      return !this.search.length
        ? this.lists
        : this.lists.filter(
            (item) =>
              item.pais
                .toLowerCase()
                .includes(this.search.toLowerCase().trim()) ||
              item.sub
                .toLowerCase()
                .includes(this.search.toLowerCase().trim()) ||
              item.sigla
                .toLowerCase()
                .includes(this.search.toLowerCase().trim())
          );
    },
  },
  mounted() {
    this.fetchCountry();
    this.$root.$on("getCountry", (data) => {
      this.fetchCountry();
    });
  },
  methods: {
    fetchCountry() {
      this.lists = [
        { sigla: "BR", sub: "Portugues", pais: "Português - Brasil" },
        {sigla:'US', sub:'Inglês', pais:'Inglês'},
        { sigla: "ES", sub: "Espanhol", pais: "Espanhol" }
      ];

      serviceMemberMeta
        .search("keys[]=pais")
        .then((resp) => {
          if (resp.pais !== undefined) {
            if (resp.pais === null) {
              this.selectCountry("BR");
            } else {
              var myArray = this.lists;
              var result = myArray.filter((x) => x.sigla === resp.pais);
              if (JSON.stringify(result) === "[]") {
                this.setCountry("BR", "Brasil");
              } else {
                this.setCountry(result[0].sigla, result[0].pais);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setCountry(code, lang) {
      if (this.pais_code != code) {
        this.pais_code = code;
        this.pais_nome = `${lang}`;
      }
      let data = {
        locale: code,
      };
      EventBus.$emit("atualizaPais", data);
      this.selectCountry(code);
      this.paises = false;
    },
    changeCountry(code, lang) {
      if (this.pais_code != code) {
        this.pais_code = code;
        this.pais_nome = `${lang}`;
      }
      let data = {
        locale: code,
      };
      EventBus.$emit("atualizaPais", data);
      this.selectCountry(code);
      this.paises = false;

      if (this.$route.path !== "/minha-conta") {
        serviceMemberMeta
          .postID({ id: "pais", value: code })
          .then((response) => {
            this.$bvToast.toast(this.$t('locales.msg'), {
            title: this.$t('locales.title'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      }
    },
    setLocale(code){
        if (this.$route.path === "/minha-conta") {
          serviceMemberMeta
            .postID({ id: "pais", value: code })
            .then((response) => {
              this.$bvToast.toast(this.$t('locales.msg'), {
              title: this.$t('locales.title'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        } else {
          service.postID({ id: "pais", value: code }).then((response) => {
            this.$bvToast.toast(this.$t('locales.msg'), {
              title: this.$t('locales.title'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
        }
    },
    selectCountry(locale) {
      const localeMap = {
        US: "en",
        ES: "es",
      };

      const defaultLocale = "pt";

      this.$i18n.locale = localeMap[locale] || defaultLocale;
    },
  },
  created() {

    this.fetchCountry();

    // EventBus.$on("changeLocale", (data) => {
    //   localStorage.setItem("location", data.locale);
    //   if (data.locale == "BR" || data.locale == "pt") {
    //     this.selectCountry("BR");
    //     this.pais_nome = "Português - Brasil";
    //     this.pais_code = "BR";
    //   } else if (
    //     data.locale == "AR" ||
    //     data.locale == "CO" ||
    //     data.locale == "CL" ||
    //     data.locale == "EC" ||
    //     data.locale == "GT" ||
    //     data.locale == "MX" ||
    //     data.locale == "PE" ||
    //     data.locale == "UY" ||
    //     data.locale == "ES"
    //   ) {
    //     this.selectCountry(data.locale);
    //     let pais = this.lists.filter((item) => item.sigla === data.locale);
    //     this.pais_nome = `${pais}`;
    //     this.pais_code = data.locale;
    //   } else {
    //     this.selectCountry("US");
    //     this.pais_nome = "English";
    //     this.pais_code = "US";
    //   }
    // });
  },
};
</script>

<style lang="scss" scoped>
.container-paises-off {
  position: absolute !important;
  right: 0;
  top: -13px !important;
  color: var(--fontcolor);
  .item-pais img {
    width: 25px;
    height: 25px;
  }
  .paises {
    position: absolute !important;
    margin-left: -20px;
    color: var(--fontcolor);
  }
}
/* // paises */
.container-paises {
  position: relative;
  z-index: 9999;
}

.input-busca {
  width: 100% !important;
  height: 40px !important;
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Inter;
  font-size: 13px !important;
  color: #81858e !important;
  transition: 0.5s;
  margin-bottom: 15px;
}
.input-busca::placeholder {
  font-family: Inter;
  font-size: 13px !important;
  color: #81858e !important;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 32px;
  top: 27px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}

.paises {
  background: var(--modal-country);
  position: absolute;
  display: grid;
  left: 5px;
  width: 249px;
  height: 183px;
  padding: 20px; 
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--profile-item-border);
  margin: 0;
}

.pais-selecionado {
  display: flex;
  justify-content: space-between;
}

.arrow-up {
  width: 18px;
  height: 18px;
  margin-right: 12px;
  margin-bottom: 8px;
  transform: rotate(180deg);
  transition: all 0.3s;
}
.girar .arrow-up {
  transform: rotate(0deg);
  transition: all 0.3s;
}
.item-pais, .item-pais-list {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 15px;
  cursor: pointer;
  padding: 7px 10px;
  padding-right: 0;
  transition: 0.3s;
  color: var(--fontcolor);
  position: relative;
  margin-bottom: 5px;
  font-weight: 500;
  justify-content: flex-start;
}

.item-pais{
  border-bottom: 1px solid var(--profile-item-border);
}

.item-pais-list{
  border-bottom: 1px solid var(--profile-item-border);
}

.item-pais-list span:hover{
  color: var(--maincolor);
}
.item-pais::before {
  content: "";
  width: 5px;
  height: 5px;
  background: transparent;
  position: absolute;
  left: -5px;
  border-radius: 50%;
}
.item-pais + .item-pais {
  margin-top: 10px;
}
.item-pais:hover {
  list-style-type:none;
}

.item-pais img {
  width: 35px;
  height: 35px;
}

/* mobile */
@media screen and (max-width: 769px) {
  .container-paises,
  .paises,
  .item-pais {
    width: auto;
  }
  .paises {
    margin-right: -7px;
    padding: 10px;
  }
  .pais-selecionado::after,
  .item-pais::before {
    display: none;
  }
  .item-pais {
    font-size: 11px;
    gap: 5px;
  }
  .item-pais img {
    width: 15px;
    height: 15px;
  }
}

.custom-border {
  border: 0.5px solid #ededf0;
  border-radius: 5px;
}

.custom-border:hover {
  border-color: #00e4a0 !important;
}

.custom-image img {
  width: 25px;
  height: 25px;
}

.custom-selected {
  padding-top: 15px;
  padding-bottom: 15px;
}

.custom-width {
  width: 100%;
}

.text-color-item {
  color: var(--text-color-item) !important;
}

.title-language{
  margin-bottom: 8px;
}

</style>